import React from "react"
import { Link, graphql } from "gatsby"

import SingleLayout from '../components/layouts/SingleLayout_10'
import Seo from "../components/seo"
import Img from "gatsby-image"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const hero = {
    title:"Keep Learning and Share Knowledge", 
    image: "/images/coding_home.png"
  }
  return (
    <>
    <SingleLayout hero={hero}>
      <Seo title={siteTitle} />
      
      <div className="row mb-5 mt-4">
        <div className="col-6">
          <h2 className="h3 mb-0">Latest Posts</h2>
        </div>
        <div className="col-6 text-right">
          <Link to="/posts/" itemProp="url" className="font-weight-bold">
            View all <i className="fas fa-angle-right fa-sm ml-1"></i>
          </Link>
        </div>
      </div>

      <div className="row mb-3">
      {posts.map(({ node }, idx) => {
        const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid
        const title = node.frontmatter.title || node.fields.slug
        
        return (
          <div key={idx} className="col-sm-6 col-lg-4 mb-3 mb-sm-8">
          <article className="card h-100">
            <div className="card-img-top position-relative">
              <Link to={node.fields.slug} itemProp="url">
                <Img className="card-img-top" fluid={featuredImgFluid} alt={title} title={title} />
                <figure className="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                    <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"/>
                  </svg>
                </figure>
              </Link>
            </div>

            <div className="card-body">
              <h3>
                <Link to={node.fields.slug} itemProp="url" className="text-inherit">
                    {title}
                </Link>
              </h3>
              <p>{node.excerpt}</p>
            </div>
          </article>
        </div>
        )
      })}
      </div>
      <div className="row mb-5 mt-4">
        <div className="col text-right">
          <Link to="/posts/" itemProp="url" className="font-weight-bold">
            View all <i className="fas fa-angle-right fa-sm ml-1"></i>
          </Link>
        </div>
      </div>
    </SingleLayout>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 33, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
